import restaurant from "./images/poseidon.jpg";
import img_card from "./images/4.jpg";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/img2.jpg";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Dumitru",
        mireasa: "Mihaela",
        data: "7 August 2022",
        data_confirmare: "29 iulie 2022",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "dumitru.paduret@gmail.com", 
       tel: "+37369712648",
       phone: "tel:+37369712648",
       viber: "viber://chat?number=%2B37369712648",
       whatsapp: "https://wa.me/+37369712648",
       messenger: "https://www.messenger.com/t/mihaela.pirgaru.5",
       tel1: "+37368355031",
       phone1: "tel:+37368355031",
       viber1: "viber://chat?number=%2B37368355031",
       whatsapp1: "https://wa.me/+37368355031",
       messenger1: "https://www.messenger.com/t/100011118303746",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Poseidon",
            data: "7 august 2022, duminică, ora 17:00",
            adress: "or. Vadul lui Voda, Parcul Nistrean",
            harta: "https://goo.gl/maps/cGj71p154Kbk7F5T9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.861645147375!2d29.1118586!3d47.0850442!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7329aa6680ed85fe!2sSala%20De%20Festivitati%20Poseidon!5e0!3m2!1sro!2s!4v1653208955756!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "NAȘII",
            nasii_nume: "Nicolae & Elena",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;